@import '../../cremedelamer2_base/scss/cremedelamer2-base-pc';
$font-times: Times, Georgia, Arial, sans-serif;

.site-header {
  &__utility {
    &-left {
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 173px;
      }
    }
    &-right {
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 250px;
      }
    }
    &-logo {
      padding-bottom: 3px;
    }
  }
  &__menu {
    top: 40px;
    @include breakpoint($landscape-up) {
      top: 0;
    }
  }
}

.site-footer {
  .site-footer-pc {
    margin-top: 30px;
    @include breakpoint($landscape-up) {
      margin-top: 0;
    }
    .site-footer {
      &__country-chooser-wrapper {
        margin-bottom: 45px;
      }
    }
  }
  &__bottom-links {
    margin-#{$rdirection}: 12%;
    width: 57%;
    @include breakpoint($large-only) {
      margin-#{$rdirection}: 25.5%;
    }
  }
}

.section-email-signup,
.section-unsubscribe,
.section-email-sign-up {
  #email-signup {
    padding: 50px 0 120px;
    .email-signup {
      &__header {
        font-size: 30px;
        text-align: #{$ldirection};
        font-family: $font--lamer-headline;
        margin-bottom: 30px;
      }
      &__required-note {
        display: none;
      }
      &__content {
        .form-text {
          border: none;
          width: 80%;
          font-size: 18px;
          color: $color-black;
          background: 0 0;
          padding: 0;
          margin: 0;
          &::-webkit-input-placeholder {
            color: $color-black;
            font-size: 20px;
          }
        }
        .form-type-textfield {
          width: 95%;
          float: #{$ldirection};
          margin-bottom: 0;
        }
        input[type='submit'] {
          &.form-submit {
            display: none;
          }
        }
        form {
          border-bottom: 1px solid $color-black;
          overflow: hidden;
          padding-bottom: 15px;
          float: #{$ldirection};
          width: 72%;
          .form-type-item {
            float: #{$ldirection};
            margin: 10px 0 0;
          }
          .form-submit {
            @include icon('arrow--right', after);
            &:after {
              @include font-smoothing(false);
              color: $color-black;
              opacity: 1;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .site-content {
    background-color: $color-light-green;
  }
}

#email-signup {
  width: 700px;
  display: block;
  margin: 0 auto 150px;
  .form-item {
    display: block;
    margin-bottom: 10px;
    .form-text {
      border: 1px solid $color-lighter-gray;
      width: 260px;
      display: block;
      &.error {
        color: $color-red;
        border-color: $color-red;
      }
    }
  }
}

.email-signup {
  &__header {
    margin: 30px 0 20px;
    font-size: 50px;
    text-align: center;
    font-family: $font--arial-times-lino;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
  }
  &__required-note {
    font-size: 13px;
  }
}

.section-customer-service-contact-us {
  #email-signup {
    @include breakpoint($landscape-up) {
      width: 770px;
    }
    .form-type-textfield {
      width: 50%;
      float: #{$ldirection};
      margin-bottom: 20px;
      label {
        font-family: $font-times;
        font-size: 13px;
        color: $color-black;
      }
      &.form-item-form-EMAIL-ADDRESS2 {
        label {
          display: none;
        }
      }
    }
    .form-type-select {
      width: 98%;
      label {
        font-family: $font--lamer-headline;
        margin: 10px 0 5px;
        display: block;
      }
      .form-select {
        width: 100%;
        height: 47px;
        font-size: 15px;
      }
    }
    .form-type-textarea {
      width: 98%;
      label {
        font-size: 13px;
      }
      .grippie {
        display: none;
      }
    }
    .form-item {
      .form-text {
        border: 1px solid $color-lighter-gray;
        width: 95%;
        height: 47px;
        display: block;
        &.error {
          color: $color-red;
          border-color: $color-red;
        }
      }
    }
    .form-type-item {
      font-family: $font--lamer-headline;
      margin: 25px 0 10px;
      width: 100%;
      float: #{$ldirection};
    }
    .form-submit {
      float: #{$ldirection};
      width: 32.4%;
      clear: both;
      margin: 20px 0 100px;
    }
  }
}

.section-customer-service-terms-conditions,
.section-customer-service-privacy-policy {
  h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .cs-content-container {
    width: 700px;
    overflow: hidden;
    margin: 0 auto;
    padding: 30px 0;
    @include breakpoint($landscape-up) {
      width: 950px;
    }
    p {
      font-size: 13px;
      margin-bottom: 7px;
    }
  }
  a[name] {
    display: block;
    padding-top: 150px;
    margin-top: -150px;
  }
}

.section-contact-us-thanks {
  .thanks-content {
    font-family: $font--lamer-headline;
    width: 700px;
    margin: 50px auto 250px;
    text-align: center;
    font-size: 60px;
  }
}

.email-signup-thanks,
.unsubscribe-thanks {
  width: 700px;
  margin: 50px auto 250px;
  text-align: center;
  .thanks-header {
    font-size: 60px;
  }
  .thanks-content {
    font-size: 15px;
  }
}

.page-product {
  .product-full {
    &__price-wrapper,
    &__offer {
      display: none;
    }
  }
}

.spp-content {
  a.selectBox {
    .selectBox-label {
      padding-inline-start: 20px;
      padding-inline-end: 20px;
    }
  }
  .regimen-module {
    .regimen-category {
      &__title::after {
        width: inherit;
      }
    }
  }
}
